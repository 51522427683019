import React from 'react';
import deleteRound from '../../../assets/images/delete-round.svg';


export default function Deleteround() {
    return (
        <div >
            <img src={deleteRound} alt="delete" onClick={e => { alert("I am an alert box!") }} />
        </div>
    )
}
