import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import chevronSVG from '../../assets/images/chevron.svg';
import styled from 'styled-components';
import Pencilround from '../Common/Button/Pencilround';
import CustomSelect from '../Common/Select/CustomSelect';

export default function InventoryReport() {
  return (
    <>
      <Container className="general-title">
        <h1>Inventory Report</h1>
        <label>Add, Manage and Edit Inventory Report</label>
      </Container>
      <Container className="white-container py-5">
        <Row className="mb-3 px-4">
          <Col lg={3} sm={12}>
            <Form>
              <Form.Group
                className="mb-3 d-flex lexique-component"
                controlId="formBasicDate"
              >
                <Form.Label className="subTitle pe-3">FROM</Form.Label>
                <Form.Control type="date" placeholder="Date" />
              </Form.Group>
            </Form>
          </Col>
          <Col lg={3} sm={12}>
            <Form>
              <Form.Group
                className="mb-3 d-flex lexique-component"
                controlId="formBasicDate"
              >
                <Form.Label className="subTitle pe-3">TO</Form.Label>
                <Form.Control type="date" placeholder="Date" />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <hr />
        <p className="header-3 px-4 pb-5">Records with Missing Information</p>
        <div className="   position-relative ">
          <Row className="px-4 justify-content-between pb-3">
            <Col className="ps-0" lg={4} md={12}>
              <input
                className="searchInput"
                type="search"
                placeholder="Search by name"
              />
            </Col>
            <Col lg={4} md={12}>
              <Form className="">
                <Form.Group className="d-flex justify-content-end" controlId="formBasicDate">
                  <Form.Label className="subTitle  align-self-center  pe-2 pt-2">
                    Records Per Page
                  </Form.Label>
                  <CustomSelect />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
        <Row className=" px-lg-2 pt-3 pb-3 align-items-center">
          <SmallFontCol className="tableTitle pe-0" lg={1}>
            ITEM NAME
            <img className="ps-2" src={chevronSVG} alt="chevron" />{' '}
          </SmallFontCol>
          <SmallFontCol className="tableTitle pe-0 " lg={1}>
            ITEM IMAGE <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle ps-5 " lg={3}>
            MANUFACTURER'S CODE{' '}
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle pe-0 ps-0 " lg={1}>
            RETAIL PRICE <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle pe-0 " lg={1}>
            DESIGNER <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle  " lg={2}>
            DATE OF SALE
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle   " lg={2}>
            PURCHASE DATE
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle ps-5" lg={1}>
            ACTION
          </SmallFontCol>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 align-items-center">
          <Col className="tableContent itemName pe-0" lg={1}>
            {' '}
            straps{' '}
          </Col>
          <Col className="tableContent pe-0 pe-0" lg={1}>
            -
          </Col>
          <Col className="tableContent ps-5" lg={3}>
            STAMPS
          </Col>
          <Col className="tableContent pe-0 ps-0" lg={1}>
            50
          </Col>
          <Col className="tableContent pe-0" lg={1}>
            Casablaca
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <SmallFontCol className="tableContent ps-5" lg={1}>
            <Pencilround />
          </SmallFontCol>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 align-items-center">
          <Col className="tableContent itemName pe-0" lg={1}>
            {' '}
            straps{' '}
          </Col>
          <Col className="tableContent pe-0 pe-0" lg={1}>
            -
          </Col>
          <Col className="tableContent ps-5" lg={3}>
            STAMPS
          </Col>
          <Col className="tableContent pe-0 ps-0" lg={1}>
            50
          </Col>
          <Col className="tableContent pe-0" lg={1}>
            Casablaca
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <SmallFontCol className="tableContent ps-5" lg={1}>
            <Pencilround />
          </SmallFontCol>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 align-items-center">
          <Col className="tableContent itemName pe-0" lg={1}>
            {' '}
            straps{' '}
          </Col>
          <Col className="tableContent pe-0 pe-0" lg={1}>
            -
          </Col>
          <Col className="tableContent ps-5" lg={3}>
            STAMPS
          </Col>
          <Col className="tableContent pe-0 ps-0" lg={1}>
            50
          </Col>
          <Col className="tableContent pe-0" lg={1}>
            Casablaca
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <SmallFontCol className="tableContent ps-5" lg={1}>
            <Pencilround />
          </SmallFontCol>
        </Row>

        <div className="page-button">
          <Button className="left-button-pagination">
            <img
              className="left-image-button-pagination"
              src={chevronSVG}
              alt="chevron"
            />
          </Button>
          <Button className="button-pagination current-page">1</Button>
          <Button className="button-pagination">2</Button>
          <Button className="button-pagination">3</Button>
          <Button className="button-pagination">4</Button>
          <Button className="button-pagination">5</Button>
          <Button className="right-button-pagination">
            <img
              className="right-image-button-pagination"
              src={chevronSVG}
              alt="chevron"
            />
          </Button>
        </div>
      </Container>
    </>
  );
}
const SmallFontCol = styled(Col)`
  font-size: 10px;
`;
