import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function ListTemplates() {
  return (
    <>
      <Container className="general-title">
        <h1>Templates</h1>
        <label>Add, Manage and Edit templates</label>
      </Container>
      <Container className="white-container py-5 px-4 container">
        <div className="d-flex justify-content-between align-items-center">
          <P className="descTitle mb-0">SMS Template</P>
          <Link to="sms-template">
            <Button variant="" className="btn-purple">
              add/Edit
            </Button>
          </Link>
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-center">
          <P className="descTitle mb-0">
            Pick-up Appointment Confirmation Email Template
          </P>
          <Button variant="" className="btn-purple">
            add/Edit
          </Button>
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-center">
          <P className="descTitle mb-0">
            Retry Appointment Confirmation Email Template
          </P>
          <Button variant="" className="btn-purple">
            add/Edit
          </Button>
        </div>
      </Container>
    </>
  );
}
const P = styled.p`
  font-size: 16px;
`;
