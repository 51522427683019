import React from 'react';
import Chart from 'react-apexcharts';
import { Button, Col, Row } from 'react-bootstrap';
import chevronSVG from '../../assets/images/chevron.svg';
import CustomSelect from '../Common/Select/CustomSelect';
export default function ByDesigner() {
  var options = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<div class="y-axis-title"> <span > <b class="y-axis">' +
          series[seriesIndex][dataPointIndex] +
          '</b> Total Revenue </span> </div>' +
          '</div>'
        );
      },
      enabled: true,
      followCursor: false,
      x: {
        show: false,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return value;
        },
      },
      z: {
        show: false,
        formatter: undefined,
        title: 'Size: ',
      },
      fixed: {
        enabled: false,
        position: 'top',
        offsetX: 100,
        offsetY: 100,
      },
    },
    fill: {
      colors: ['rgba(212, 189, 180,1)'],
    },
    series: [
      {
        name: 'Inflation',
        data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
      },
    ],
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        horizontal: false,
        columnWidth: '35%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: [
        'Designer1',
        'Designer2',
        'Designer3',
        'Designer4',
        'Designer5',
        'Designer6',
        'Designer7',
      ],

      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D4BDB4',
            colorTo: '#BED1E6',

            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      fill: {
        colors: ['#f00'],
      },
      tooltip: {
        followCursor: true,
        enabled: false,
        x: {
          show: false,
        },

        y: {
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w }
          ) {
            return value;
          },
        },
      },

      labels: {
        show: true,
        rotate: -90,
        rotateAlways: true,
        style: {
          colors: ['#000000'],
          fontSize: '12px',
          fontFamily: 'Poppins, Medium',
          fontWeight: 700,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
        style: {
          colors: ['rgba(124,130,138,1)'],
          fontSize: '12px',
          fontFamily: 'Poppins, Medium',
          fontWeight: 700,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
      tooltip: {
        followCursor: true,
        enabled: false,
      },
    },
    title: {
      text: undefined,
    },
    grid: {
      show: false,
    },

    legend: {
      show: false,
    },
  };
  let series = [{ data: [20, 250, 500, 750, 300, 25, 90] }];
  return (
    <div>
           <div className='d-flex justify-content-between align-items-center'>
        <div className='Analytics-subtitle'>By Designer</div>
      <div>  <CustomSelect /></div>
      </div>
      <div className="chart-border pt-4">
        <Row>
          <Col lg={5} md={12}>
            <div className="chart-title d-flex ps-4 pt-3">Total Revenue</div>
          </Col>
          <Col lg={3} md={12}></Col>
          <Col lg={4} md={12}>
            <div className=" ">
              <Button className="left-button-pagination">
                <img
                  className="left-image-button-pagination"
                  src={chevronSVG}
                  alt="chevron"
                />
              </Button>
              <Button className="button-pagination-graph ">01-31 NOV</Button>

              <Button className="right-button-pagination">
                <img
                  className="right-image-button-pagination"
                  src={chevronSVG}
                  alt="chevron"
                />
              </Button>
            </div>
          </Col>
        </Row>

        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="472px"
          style={{ position: 'relative', right: -10, bottom: -5 }}
        />
      </div>
      <p className="analytics-purpul-subtitle pt-3">
        EXPORT REVENUE BASED ON DESIGNER
        <img
          className="right-image-button-pagination  pt-2 ps-1"
          src={chevronSVG}
          alt="chevron"
        />
      </p>
      <hr />
      <p className="analytics-purpul-subtitle pt-2">
        EXPORT PROFIT BASED ON DESIGNER
        <img
          className="right-image-button-pagination  pt-2 ps-1"
          src={chevronSVG}
          alt="chevron"
        />
      </p>
      <hr />
    </div>
  );
}
