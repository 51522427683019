import React from 'react';
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from 'react-bootstrap';

export default function SMSTemplate() {
  return (
    <>
      <Container className="general-title">
        <h1>Templates</h1>
        <label>Add, Manage and Edit templates</label>
      </Container>
      <Container className="white-container py-4 px-4 mb-3">
        <p className="descTitle">SMS Template for Review Requests</p>
        <hr />
        <p className="subTitle ">Body</p>
        <Row>
          <Col lg={6}>
            <FloatingLabel controlId="floatingTextareaSMS" label="">
              <Form.Control
                as="textarea"
                placeholder="Enter Body Text"
                style={{ height: '100px' }}
              />
            </FloatingLabel>
            <div className="d-flex justify-content-between mt-5">
              <Button variant="" className="btn-border">
                {' '}
                Cancel
              </Button>
              <Button variant="" className="btn-purple">
                Save{' '}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
