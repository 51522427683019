import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import chevronSVG from '../../assets/images/chevron.svg';
import Deleteround from '../Common/Button/Deleteround';
import Pencilround from '../Common/Button/Pencilround';
import CustomSelect from '../Common/Select/CustomSelect';

export default function Orders() {
  return (
    <>
      <Container className="general-title">
        <h1>Orders</h1>
        <label>Add, Manage and Edit Orders</label>
      </Container>
      <Container className="white-container  py-5">
        <div className="  position-relative ">
          <Row className=' align-items-center pb-3 px-4 '>
            <Col lg={3} md={12}>
              <input
                className="searchInput"
                type="search"
                placeholder="Search"
              />
            </Col>
            <Col lg={4} md={12}>
              <Form className="">
                <Form.Group className="d-flex justify-content-center " controlId="formBasicRecords">
                  <Form.Label className="subTitle  align-self-center text-end ps-4 pe-2 pt-2">
                    Records Per Page
                  </Form.Label>
                  <CustomSelect />
                </Form.Group>
              </Form>
            </Col>
            <Col lg={5} md={12} className="d-flex justify-content-evenly ps-4">
              <Link to="add-exisiting-customer">
                <Button variant="" className="btn-darkpurple">
                  Add existing customer
                </Button>
              </Link>
              <Link to="add-new-customer">
                <Button variant="" className="btn-purple">
                  Add New customer
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
        <Row className=" px-lg-2 py-2 justify-content-evenly">
          <Col className="tableTitle" lg={2}>
            Order Date
            <img className="ps-2" src={chevronSVG} alt="chevron" />{' '}
          </Col>
          <Col className="tableTitle ps-0 pe-0" lg={2}>
            Bride Name <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2}>
            Amount (USD) <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle ps-0 pe-0" lg={1}>
            Paid (USD) <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2}>
            Refund (USD)
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle ps-0 pe-0" lg={2}>
            Remaining (USD){' '}
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={1}>
            ACTIONS
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 align-items-center justify-content-evenly">
          <Col className="tableContent" lg={2}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="itemName ps-0 pe-0" lg={2}>
            BOBBILYN BRUURSEMA
          </Col>
          <Col className="content-midsize" lg={2}>
            1908
          </Col>
          <Col className="content-midsize" lg={1}>
            954
          </Col>
          <Col className="content-midsize" lg={2}>
            0
          </Col>
          <Col className="content-midsize" lg={2}>
            954
          </Col>
          <Col className="tableContent d-flex" lg={1}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
        <Link to="order-details">
          <Row className=" px-lg-2 py-1 align-items-center tableContentBg mb-2 justify-content-evenly">
            <Col className="tableContent" lg={2}>
              02/07/2021 AT 1:00PM
            </Col>
            <Col className="itemName ps-0 pe-0" lg={2}>
              BOBBILYN BRUURSEMA
            </Col>
            <Col className="content-midsize" lg={2}>
              1908
            </Col>
            <Col className="content-midsize" lg={1}>
              954
            </Col>
            <Col className="content-midsize" lg={2}>
              0
            </Col>
            <Col className="content-midsize" lg={2}>
              954
            </Col>
            <Col className="tableContent d-flex" lg={1}>
              <div className="pe-2">
                <Pencilround />
              </div>
              <div>
                <Deleteround />
              </div>
            </Col>
          </Row>
        </Link>
        <Link to="order-details">
          <Row className=" px-lg-2 py-1 align-items-center tableContentBg mb-2 justify-content-evenly">
            <Col className="tableContent" lg={2}>
              02/07/2021 AT 1:00PM
            </Col>
            <Col className="itemName ps-0 pe-0" lg={2}>
              BOBBILYN BRUURSEMA
            </Col>
            <Col className="content-midsize" lg={2}>
              1908
            </Col>
            <Col className="content-midsize" lg={1}>
              954
            </Col>
            <Col className="content-midsize" lg={2}>
              0
            </Col>
            <Col className="content-midsize" lg={2}>
              954
            </Col>
            <Col className="tableContent d-flex" lg={1}>
              <div className="pe-2">
                <Pencilround />
              </div>
              <div>
                <Deleteround />
              </div>
            </Col>
          </Row>
        </Link>

      </Container>
    </>
  );
}
