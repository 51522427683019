import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import icon from '../../assets/images/arrow.svg';
import Deleteround from '../Common/Button/Deleteround';
import Pencilround from '../Common/Button/Pencilround';
export default function AddNewDesigner() {
  return (
    <>
      <Container className="general-title">
        <h1>Add New Designer</h1>
        <label>Add New Designer Details</label>
      </Container>
      <Container className="white-container addNewDesigner p-4 mb-5">
        <div className="mb-5">
          <img src={icon} alt="" />
          <Link className="backLink ps-1" to="/inventory">
            BACK TO INVENTORY
        </Link>
        </div>
        <p className="header-3">Designer Detail</p>
        <Row className="mb-3">
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicCountry">
                <Form.Label className="subTitle pt-3">DESIGNER'S NAME</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter designer name"
                />
              </Form.Group>
            </Form>
            <Col className="d-flex justify-content-end">
              <Button variant="" className="btn-purple">
                ADD DESIGNER
            </Button>
            </Col>
          </Col>
        </Row>

        <Row className=" px-lg-2 pt-3 pb-3 justify-content-evenly">
          <Col className="tableTitle  " lg={2}>
            ID
        </Col>
          <Col className="tableTitle " lg={8}>
            DESIGNER NAME
        </Col>

          <Col className="tableTitle" lg={2}>
            ACTIONS
        </Col>
        </Row>
        <Row className=" px-lg-2 pt-3 pb-3 tableContentBg mb-3 justify-content-evenly">
          <Col className="itemName  " lg={2}>
            99355V
        </Col>
          <Col className="tableContent " lg={8}>
            Tiara
        </Col>

          <Col className="tableContent d-flex" lg={2}>
            <div className="pe-2">
              <Pencilround />
            </div>

            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
