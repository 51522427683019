import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import Deleteround from '../../Common/Button/Deleteround';
import Pencilround from '../../Common/Button/Pencilround';
import CustomSelect from '../../Common/Select/CustomSelect';

export default function AddItems() {
  return (
    <Container className="white-container-adjust pt-4 mb-4 pb-4 ">
      <p className="descTitle">Add Items</p>
      <Row className="mb-3">
        <Col lg={3} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicInventory">
              <Form.Label className="subTitle">INVENTORY</Form.Label>
              <CustomSelect />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3} sm={12}>
          <Form.Group className="mb-3" controlId="formBasicOrderType">
            <Form.Label className="subTitle">ORDER TYPE</Form.Label>
            <CustomSelect />
          </Form.Group>
        </Col>
        <Col lg={2} sm={12}>
          <Form.Group className="mb-3" controlId="formBasicItemCode">
            <Form.Label className="subTitle">ITEM CODE</Form.Label>
            <Form.Control type="text" placeholder="Enter Item Code" />
          </Form.Group>
        </Col>
        <Col lg={2} sm={12}>
          <Form.Group className="mb-3" controlId="formBasicDesigner">
            <Form.Label className="subTitle">DESIGNER</Form.Label>
            <Form.Control type="text" placeholder="Select Designer" />
          </Form.Group>
        </Col>
        <Col lg={2} sm={12}>
          <Form.Group className="mb-3" controlId="formBasicCost">
            <Form.Label className="subTitle">Cost (USD)</Form.Label>
            <Form.Control type="text" placeholder="Select Designer" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={12} sm={12}>
          <Form.Group className="mb-3" controlId="formBasicNote">
            <Form.Label className="subTitle">NOTE</Form.Label>
            <Form.Control type="text" placeholder="Enter Note" id="note" />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end py-4">
          <Button variant="" className="btn-purple">
            {' '}
            REFUND
          </Button>
        </Col>
      </Row>
      <Container className='pt-5'>
        <Row className="Table">
          <Col lg={1} sm={12}>
            <p className="subTitle-t">INVENTORY</p>
            <p className="text-t">99355V</p>
          </Col>
          <Col lg={2} sm={12}>
            <p className="subTitle-t">DATE ADDED</p>
            <p className="text-t">02/07/2021 To At 1:00PM</p>
          </Col>
          <Col lg={1} sm={12}>
            <p className="subTitle-t">ORDER</p>
            <p className="text-t">Order</p>
          </Col>
          <Col lg={1} sm={12}>
            <p className="subTitle-t">ITEM CODE</p>
            <p className="text-t">y21983ST4</p>
          </Col>
          <Col lg={1} sm={12}>
            <p className="subTitle-t">DESIGNER</p>
            <p className="text-t">ST4</p>
          </Col>
          <Col lg={1} sm={12}>
            <p className="subTitle-t">COST(USD)</p>
            <p className="text-t">1800</p>
          </Col>
          <Col lg={4} sm={12}>
            <p className="subTitle-t">COST(USD)</p>
            <p className="text-t">
              bust 41 waist 36 hips 45 ivory/ivory size 14 12/14 ship #89163
            </p>
          </Col>
          <Col lg={1} sm={12}>
            <p className="subTitle-t">ACTIONS</p>
            <div className="text-t d-flex">
              <Pencilround />
              <div className="Deletebutton-table">
                <Deleteround />{' '}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
