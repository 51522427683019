import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Deleteround from '../Common/Button/Deleteround';
import Pencilround from '../Common/Button/Pencilround';
import CustomSelect from '../Common/Select/CustomSelect';

export default function SearchAppointment() {
  return (
    <>
      <Container className="general-title">
        <h1>Search Appointment</h1>
        <label>Search, Manage and Edit appointments</label>
      </Container>

      <Container className="white-container py-5 mb-5">
        <div className="   position-relative">
          <Row className="align-items-center pb-3 px-4">
            <Col lg={6} md={12}>
              <input
                className="searchInput"
                type="search"
                placeholder="Search"
              />
            </Col>
            <Col lg={6} md={12}>
              <Form className="">
                <Form.Group className="d-flex " controlId="formBasicRecords">
                  <Form.Label className="subTitle w-100 align-self-center text-end pe-2 pt-2 ">
                    Records Per Page
                  </Form.Label>
                  <CustomSelect />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
        <Row className=" px-lg-2 pt-2 pb-0 align-items-center">
          <Col className="tableTitle" lg={1}>
            Type{' '}
          </Col>
          <Col className="tableTitle" lg={1}>
            Bride name{' '}
          </Col>
          <Col className="tableTitle" lg={2}>
            Phone{' '}
          </Col>
          <Col className="tableTitle " lg={2}>
            Description{' '}
          </Col>
          <Col className="tableTitle" lg={1}>
            EStimated budget{' '}
          </Col>
          <Col className="tableTitle" lg={1}>
            Start date
          </Col>
          <Col className="tableTitle" lg={1}>
            End date
          </Col>
          <Col className="tableTitle" lg={1}>
            Confirmed
          </Col>
          <Col className="tableTitle ps-0 pe-0" lg={1}>
            Date booked
          </Col>
          <Col className="tableTitle" lg={1}>
            ACTIONS
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 align-items-center">
          <Col className="tableContent" lg={1}>
            AISHA bubarak{' '}
          </Col>
          <Col className="content-midsize" lg={1}>
            AISHA bubarak
          </Col>
          <Col className="content-midsize" lg={2}>
            (313) 502-9704
          </Col>
          <Col className="content-midsize desc" lg={2}>
            Ballgown JIW only size 6 2 ppl (mom or MIL) no card on file, delete
            after 2/25
          </Col>
          <Col className="content-midsize" lg={1}>
            1500USD
          </Col>
          <Col className="content-midsize" lg={1}>
            10/11/2021
          </Col>
          <Col className="content-midsize" lg={1}>
            10/11/2021
          </Col>
          <Col className="content-midsize" lg={1}>
            NO
          </Col>
          <Col className="content-midsize ps-0 pe-0" lg={1}>
            10/11/2021
          </Col>
          <Col className="tableContent d-flex" lg={1}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 align-items-center">
          <Col className="tableContent" lg={1}>
            AISHA bubarak{' '}
          </Col>
          <Col className="content-midsize" lg={1}>
            AISHA bubarak
          </Col>
          <Col className="content-midsize" lg={2}>
            (313) 502-9704
          </Col>
          <Col className="content-midsize" lg={2}>
            Ballgown JIW only size 6 2 ppl (mom or MIL) no card on file, delete
            after 2/25
          </Col>
          <Col className="content-midsize" lg={1}>
            1500USD
          </Col>
          <Col className="content-midsize" lg={1}>
            10/11/2021
          </Col>
          <Col className="content-midsize" lg={1}>
            10/11/2021
          </Col>
          <Col className="content-midsize" lg={1}>
            NO
          </Col>
          <Col className="content-midsize ps-0 pe-0" lg={1}>
            10/11/2021
          </Col>
          <Col className="tableContent d-flex" lg={1}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>

        <Row className=" px-lg-2 py-1 tableContentBg mb-2 align-items-center">
          <Col className="tableContent" lg={1}>
            AISHA bubarak{' '}
          </Col>
          <Col className="content-midsize" lg={1}>
            AISHA bubarak
          </Col>
          <Col className="content-midsize" lg={2}>
            (313) 502-9704
          </Col>
          <Col className="content-midsize" lg={2}>
            Ballgown JIW only size 6 2 ppl (mom or MIL) no card on file, delete
            after 2/25
          </Col>
          <Col className="content-midsize" lg={1}>
            1500USD
          </Col>
          <Col className="content-midsize" lg={1}>
            10/11/2021
          </Col>
          <Col className="content-midsize" lg={1}>
            10/11/2021
          </Col>
          <Col className="content-midsize" lg={1}>
            NO
          </Col>
          <Col className="content-midsize ps-0 pe-0" lg={1}>
            10/11/2021
          </Col>
          <Col className="tableContent d-flex" lg={1}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>

        <Row className=" px-lg-2 py-1 tableContentBg mb-2 align-items-center">
          <Col className="tableContent" lg={1}>
            AISHA bubarak{' '}
          </Col>
          <Col className="content-midsize" lg={1}>
            AISHA bubarak
          </Col>
          <Col className="content-midsize" lg={2}>
            (313) 502-9704
          </Col>
          <Col className="content-midsize" lg={2}>
            Ballgown JIW only size 6 2 ppl (mom or MIL) no card on file, delete
            after 2/25
          </Col>
          <Col className="content-midsize" lg={1}>
            1500USD
          </Col>
          <Col className="content-midsize" lg={1}>
            10/11/2021
          </Col>
          <Col className="content-midsize" lg={1}>
            10/11/2021
          </Col>
          <Col className="content-midsize" lg={1}>
            NO
          </Col>
          <Col className="content-midsize ps-0 pe-0" lg={1}>
            10/11/2021
          </Col>
          <Col className="tableContent d-flex" lg={1}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>

      </Container>
    </>
  );
}
