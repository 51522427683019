import React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import chevronSVG from "../../assets/images/chevron.svg";
import CustomSelect from '../Common/Select/CustomSelect';
export default function ByDress() {

  return (
    <div>
           <div className='d-flex justify-content-between align-items-center'>
        <div className='Analytics-subtitle'>By Dress</div>
      <div>  <CustomSelect /></div>
      </div>
      <div className='chart-border pt-4'>
        <Row>
          <Col lg={12} md={12}>
            <div className='chart-title d-flex ps-4 pt-3'>Total Number of sales</div>
          </Col>



        </Row>

        <Row className=' px-lg-2 pt-3 pb-3 '>
          <Col className='tableTitle  ' lg={4}>DRESS CODE<img className='ps-2' src={chevronSVG} alt='chevron' /> </Col>
          <Col className='tableTitle  ' lg={4}>DESIGNER<img className='ps-2' src={chevronSVG} alt='chevron' /></Col>
          <Col className='tableTitle  ' lg={4}>NUMBER OF SALES<img className='ps-2' src={chevronSVG} alt='chevron' /></Col>
        </Row>
        <Row className=' px-lg-2 pt-3 pb-3 tableContentBg mb-3 ms-0 me-0'>
          <Col className='tableContent ' lg={4}>768</Col>
          <Col className='tableContent' lg={4}><b>TIARA</b></Col>
          <Col className='tableContent pe-0' lg={4}>123123</Col>

        </Row>
        <Row className=' px-lg-2 pt-3 pb-3 tableContentBg mb-3 ms-0 me-0'>
          <Col className='tableContent' lg={4}>768</Col>
          <Col className='tableContent' lg={4}>TIARA</Col>
          <Col className='tableContent ' lg={4}>123123</Col>

        </Row>
        <Row className=' px-lg-2 pt-3 pb-3 tableContentBg mb-3 ms-0 me-0'>
          <Col className='tableContent' lg={4}>768</Col>
          <Col className='tableContent' lg={4}>TIARA</Col>
          <Col className='tableContent ' lg={4}>123123</Col>

        </Row>
        <Row className=' px-lg-2 pt-3 pb-3 tableContentBg mb-3 ms-0 me-0'>
          <Col className='tableContent' lg={4}>768</Col>
          <Col className='tableContent' lg={4}>TIARA</Col>
          <Col className='tableContent ' lg={4}>123123</Col>

        </Row>
        <Row className=' px-lg-2 pt-3 pb-3 tableContentBg mb-3 ms-0 me-0'>
          <Col className='tableContent' lg={4}>768</Col>
          <Col className='tableContent' lg={4}>TIARA</Col>
          <Col className='tableContent ' lg={4}>123123</Col>

        </Row>
        <div className="page-button mb-3">
          <Button className="left-button-pagination"><img className='left-image-button-pagination' src={chevronSVG} alt='chevron' /></Button>
          <Button className="button-pagination current-page">1</Button>
          <Button className="button-pagination">2</Button>
          <Button className="button-pagination">3</Button>
          <Button className="button-pagination">4</Button>
          <Button className="button-pagination">5</Button>
          <Button className="right-button-pagination"><img className='right-image-button-pagination' src={chevronSVG} alt='chevron' /></Button>
        </div>
      </div>
      <p className='analytics-purpul-subtitle pt-3'>EXPORT NUMBER OF SALES RANKING BY DRESS<img className='right-image-button-pagination  pt-2 ps-1' src={chevronSVG} alt='chevron' /></p>
      <hr />
      <p className='analytics-purpul-subtitle pt-2'>EXPORT REVENUE RANKING BY DRESS<img className='right-image-button-pagination  pt-2 ps-1' src={chevronSVG} alt='chevron' /></p>
      <hr />
      <p className='analytics-purpul-subtitle pt-2'>EXPORT PROFIT RANKING BY DRESS<img className='right-image-button-pagination  pt-2 ps-1' src={chevronSVG} alt='chevron' /></p>
      <hr />
    </div>
  );
}
