import React from 'react'
import Copy from '../../../assets/images/copy-round.svg';

export default function CopyRound() {
    return (
        <div >
            <img src={Copy} alt="copy" onClick={e => { alert("I am an alert box!") }} />
        </div>
    )
}
