import React from "react";
import { Map, GoogleApiWrapper, HeatMap } from "google-maps-react";



class MapContainer extends React.Component {
  render() {
    const gradient = [
       "rgba(0, 0, 250, 0)",
      "rgba(0, 0, 255, 1)",
      "rgba(0, 63, 255, 1)",
      "rgba(0, 127, 255, 1)",
      "rgba(0, 255, 255, 1)",
       "rgba(0, 255, 255, 1)",
      "rgba(125, 249, 30, 1)",
      "rgba(125, 249, 30, 1)",
      "rgba(125, 249, 30, 1)",
      "rgba(125, 249, 30, 1)",
      "rgba(125, 249, 30, 1)",
      "rgba(255, 255, 0, 1)",
      "rgba(255, 255, 0, 1)",
      "rgba(255, 255, 0, 1)",
      "rgba(127, 0, 63, 1)",
      "rgba(191, 0, 31, 1)",
      "rgba(255, 0, 0, 1)",
      "rgba(255, 0, 0, 1)",
      "rgba(255, 0, 0, 1)",
      "rgba(255, 0, 0, 1)",
      "rgba(255, 0, 0, 1)",

    ];
    return (
      <div className="map-container">

        <Map
 
          google={this.props.google}
          className={"map"}
          zoom={3}
          initialCenter={this.props.center}
          onReady={this.handleMapReady}
        >
          <HeatMap
            gradient={gradient}
            positions={this.props.data}
            opacity={1}
            radius={20}
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAHWFdemDp61mySikz9X1WdwdY8Ywa2aEY",
  libraries: ["visualization"]
})(MapContainer);
