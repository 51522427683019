import React from 'react';
import { Col, Row } from 'react-bootstrap';
import OrderDetails from './OrderDetails';
import AddItems from './AddItems';
import AddRefund from './AddRefund';
import Summary from './Summary';
import AddPayment from './AddPayment';
import PostSale from './PostSale';
import WholeSale from './WholeSale';

export default function ManageOrders() {
  return (
    <div className='manageOrders'>
      <Row>
        <Col>
          <OrderDetails />
        </Col>
      </Row>
      <Row>
        <Col>
          <AddItems />
        </Col>
      </Row>
      <Row className='row-width'>
        <Col>
          <AddPayment />
          <AddRefund />
          <PostSale />
        </Col>
        <Col>
          <Summary />
          <WholeSale />
        </Col>
      </Row>
    </div>
  );
}
