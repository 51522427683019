import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AreaHeatmap from './AreaHeatmap';
import ByAdvertisingPlatform from './ByAdvertisingPlatform';
import ByDesigner from './ByDesigner';
import ByDress from './ByDress';
import ByStylist from './ByStylist';
import OtherSourcesAdvertising from './OtherSourcesAdvertising';

export default function Analytics() {

  return (
    <div>
      <Container className="general-title">
        <h1>Analytics</h1>
        <label>View and check your data</label>
      </Container>
      <Container className='white-container pt-4 pb-5 '>
        <Row>
          <Col lg={6} md={12}>
            <AreaHeatmap />
          </Col>
          <Col lg={6} md={12}>
            <ByDesigner />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <ByDress />
          </Col>
          <Col lg={6} md={12}>
            <ByStylist />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <ByAdvertisingPlatform />
          </Col>
          <Col lg={6} md={12}>
            <OtherSourcesAdvertising />
          </Col>
        </Row>
      </Container>





    </div>
  );
}
