import React from 'react'
import { Col, Row } from 'react-bootstrap'
import chevronSVG from "../../assets/images/chevron.svg";


export default function PickupsWeek() {
    return (
        <div className='grey-border py-2 mt-4'>
            <p className='dashboard-title  ps-2'>Pickups for the Week</p>
            <Row className=' px-lg-2 mx-0 pb-3 align-items-center justify-content-evenly'>
                <Col className='tableTitle ps-0 pe-0' lg={2}>PICKUP #<img className='ps-2' src={chevronSVG} alt='chevron' /> </Col>
                <Col className='tableTitle ps-5' lg={5}>BRIDE NAME <img className='ps-2' src={chevronSVG} alt='chevron' /></Col>
                <Col className='tableTitle ' lg={5}>TIME <img className='ps-2' src={chevronSVG} alt='chevron' /></Col>
            </Row>
            <Row className=' px-lg-2  py-2 align-items-center tableNewAppBg mb-2 mx-0 justify-content-evenly'>
                <Col className='content-midsize ' lg={2}>1</Col>
                <Col className='tableContent text-uppercase ps-5' lg={5}>Jennifer Blackly</Col>
                <Col className='content-midsize' lg={5}>02/01/2021 at 1:00PM</Col>
            </Row>
            <Row className=' px-lg-2  py-2 align-items-center tableNewAppBg mb-2 mx-0 justify-content-evenly'>
                <Col className='content-midsize ' lg={2}>2</Col>
                <Col className='tableContent text-uppercase ps-5' lg={5}>Jennifer Blackly</Col>
                <Col className='content-midsize' lg={5}>02/01/2021 at 1:00PM</Col>
            </Row>
            <Row className=' px-lg-2  py-2 align-items-center tableNewAppBg mb-2 mx-0 justify-content-evenly'>
                <Col className='content-midsize ' lg={2}>3</Col>
                <Col className='tableContent text-uppercase ps-5' lg={5}>Jennifer Blackly</Col>
                <Col className='content-midsize' lg={5}>02/01/2021 at 1:00PM</Col>
            </Row>
            <Row className=' px-lg-2  py-2 align-items-center tableNewAppBg mb-2 mx-0 justify-content-evenly'>
                <Col className='content-midsize ' lg={2}>4</Col>
                <Col className='tableContent text-uppercase ps-5' lg={5}>Jennifer Blackly</Col>
                <Col className='content-midsize' lg={5}>02/01/2021 at 1:00PM</Col>
            </Row>
            <Row className=' px-lg-2  py-2 align-items-center tableNewAppBg mb-2 mx-0 justify-content-evenly'>
                <Col className='content-midsize ' lg={2}>5</Col>
                <Col className='tableContent text-uppercase ps-5' lg={5}>Jennifer Blackly</Col>
                <Col className='content-midsize' lg={5}>02/01/2021 at 1:00PM</Col>
            </Row>
            <Row className=' px-lg-2  py-2 align-items-center tableNewAppBg mb-2 mx-0 justify-content-evenly'>
                <Col className='content-midsize ' lg={2}>6</Col>
                <Col className='tableContent text-uppercase ps-5' lg={5}>Jennifer Blackly</Col>
                <Col className='content-midsize' lg={5}>02/01/2021 at 1:00PM</Col>
            </Row>
            <Row className=' px-lg-2  py-2 align-items-center tableNewAppBg mb-2 mx-0 justify-content-evenly'>
                <Col className='content-midsize ' lg={2}>7</Col>
                <Col className='tableContent text-uppercase ps-5' lg={5}>Jennifer Blackly</Col>
                <Col className='content-midsize' lg={5}>02/01/2021 at 1:00PM</Col>
            </Row>
            <Row className=' px-lg-2  py-2 align-items-center tableNewAppBg mb-2 mx-0 justify-content-evenly'>
                <Col className='content-midsize ' lg={2}>8</Col>
                <Col className='tableContent text-uppercase ps-5' lg={5}>Jennifer Blackly</Col>
                <Col className='content-midsize' lg={5}>02/01/2021 at 1:00PM</Col>
            </Row>
            <Row className=' px-lg-2  py-2 align-items-center tableNewAppBg mb-2 mx-0 justify-content-evenly'>
                <Col className='content-midsize ' lg={2}>9</Col>
                <Col className='tableContent text-uppercase ps-5' lg={5}>Jennifer Blackly</Col>
                <Col className='content-midsize' lg={5}>02/01/2021 at 1:00PM</Col>
            </Row>

        </div>
    )
}
