import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

export default function Summary() {
  return (
    <Container className="white-container-adjust pt-4 mb-4 pb-4 ">
      <p className="descTitle">Summary</p>
      <Row className="mb-3">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicSubTotal">
            <Form.Label className="subTitle">SUB TOTAL</Form.Label>
            <Form.Control type="text" placeholder="Sub Total" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicTaxRate">
            <Form.Label className="subTitle">Tax Rate</Form.Label>
            <Form.Control type="text" placeholder="Tax Rate" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicSubTax">
            <Form.Label className="subTitle">SUB TOTAL + TAX</Form.Label>
            <Form.Control type="text" placeholder="SUB TOTAL + TAX" />
          </Form.Group>
        </Form>

        <Form.Group className="mb-3" controlId="formBasicRushFee">
          <Form.Label className="subTitle">RUSH FEE</Form.Label>
          <Form.Control type="text" placeholder="Rush Fee" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicGrandTotal">
          <Form.Label className="subTitle">GRAND TOTAL</Form.Label>
          <Form.Control type="text" placeholder="Grand Total" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPaid">
          <Form.Label className="subTitle">Paid</Form.Label>
          <Form.Control type="text" placeholder="Paid" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicRefund">
          <Form.Label className="subTitle">REFUND AMOUNT</Form.Label>
          <Form.Control type="text" placeholder="Refund Amount" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicRemain">
          <Form.Label className="subTitle">REMAINING</Form.Label>
          <Form.Control type="text" placeholder="Remaining" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicStylist">
          <Form.Label className="subTitle">
            STYLIST WHO MADE THE SALE
          </Form.Label>
          <Form.Control type="text" placeholder="Name will be visible here" />
        </Form.Group>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end">
          <Button variant="" className="btn-border me-3 btn">
            {' '}
            SAVE ORDER{' '}
          </Button>
          <Button variant="" className="btn-purple">
            {' '}
            REFUND
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
