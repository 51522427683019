import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import icon from '../../assets/images/arrow.svg';
export default function AddingNewCustomer() {
  return (
    <>
      <Container className="general-title">
        <h1>Add New Customer</h1>
        <label>Add New Customer Details</label>
      </Container>
      <Container className="white-container pt-4 pb-4 ps-4 pe-4 mb-5">
        <div className="mb-5">
          <img src={icon} alt="" />
          <Link to="/orders" className="backLink ps-1">
            Back to Orders
          </Link>
        </div>
        <p className="header">New Customer Details</p>
        <Row className="mb-3">
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label className="subTitle">BRIDE'S FIRST NAME</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter bride's first name"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label className="subTitle">
                  BRIDE'S WEDDING DATE
                </Form.Label>
                <Form.Control type="date" placeholder="Choose wedding date" />
              </Form.Group>
            </Form>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label className="subTitle">BRIDE'S LAST NAME</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter bride's first name"
              />
            </Form.Group>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicNum">
                <Form.Label className="subTitle">PHONE NUMBER</Form.Label>

                <PhoneInput country={'us'} />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicAddress">
                <Form.Label className="subTitle">Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Please enter email address"
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <hr></hr>

        <Row className="mb-3 switch">
          <p className="titleSection">Where did you hear about us?</p>
          <Col lg={2}>
            <Form.Group className="mb-3" controlId="formBasicCheckboxWord">
              <Form.Check type="checkbox" label="Word of Mouth" />
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Form.Group className="mb-3" controlId="formBasicCheckboxFacebook">
              <Form.Check type="checkbox" label="Facebook" />
            </Form.Group>
          </Col>

          <Col lg={2}>
            <Form.Group className="mb-3" controlId="formBasicCheckboxInstagram">
              <Form.Check type="checkbox" label="Instagram" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 switch align-items-center">
          <Col lg={2}>
            <Form.Group className="mb-3" controlId="formBasicCheckboxGoogle">
              <Form.Check type="checkbox" label="Google" />
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Form.Group className="mb-3" controlId="formBasicCheckboxYelp">
              <Form.Check type="checkbox" label="Yelp" />
            </Form.Group>
          </Col>

          <Col lg={2}>
            <Form.Group className="mb-3" controlId="formBasicCheckboxOther">
              <Form.Check type="checkbox" label="Other" />
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group className="mb-3" controlId="formBasicSpecify">
              <Form.Control type="text" placeholder="Please specify" />
            </Form.Group>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="" className="btn-purple me-3">
              {' '}
              Reset{' '}
            </Button>
            <Button variant="" className="btn-purple">
              {' '}
              Submit
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
