import React from 'react';
import { Container, Col, Form, Row, Button } from 'react-bootstrap';
import styled from 'styled-components';
import Pencilround from '../Common/Button/Pencilround';
import CustomSelect from '../Common/Select/CustomSelect';

export default function BridalEntryDatabase() {
  return (
    <>
      <Container className="general-title">
        <h1>Bridal Entry Database</h1>
        <label>Requests for appointments</label>
      </Container>
      <Container className="white-container py-5 mb-5 BridalEntryDatabase">
        <div className="   position-relative">
          <Row className="align-items-center pb-3 px-4">
            <Col lg={4} md={12}>
              <input
                className="searchInput"
                type="search"
                placeholder="Search"
              />
            </Col>
            <Col lg={4} md={12}>
              <Form className="">
                <Form.Group
                  className="d-flex justify-content-center"
                  controlId="formBasicRecords"
                >
                  <Form.Label className="subTitle align-self-center text-end pe-2 pt-2">
                    Records Per Page
                  </Form.Label>
                  <CustomSelect />
                </Form.Group>
              </Form>

            </Col>
            <Col lg={4} md={12} className="d-flex justify-content-end">
              <Button variant="" className="btn-border">
                My Calendar
              </Button>
            </Col>
          </Row>
        </div>
        <Row className=" px-lg-2 py-2 justify-content-evenly align-items-center">
          <Col className="tableTitle" lg={1}>
            ID{' '}
          </Col>
          <Col className="tableTitle pe-0" lg={1}>
            Bride name{' '}
          </Col>
          <Col className="tableTitle pe-0" lg={1}>
            Called once{' '}
          </Col>
          <Col className="tableTitle pe-0" lg={1}>
            Called Twice{' '}
          </Col>
          <Col className="tableTitle pe-0" lg={1}>
            Called Thrice{' '}
          </Col>
          <Col className="tableTitle pe-0 " lg={1}>
            Texted{' '}
          </Col>
          <Col className="tableTitle pe-0" lg={1}>
            Wedding Date{' '}
          </Col>
          <Col className="tableTitle pe-0" lg={1}>
            Book
          </Col>
          <Col className="tableTitle " lg={1}>
            Confirmed
          </Col>
          <Col className="tableTitle pe-0" lg={1}>
            Date Booked
          </Col>
          <Col className="tableTitle ps-lg-5" lg={1}>
            notes
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="tableContent" lg={1}>
            20008{' '}
          </Col>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            Chandrika Paytons{' '}
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0 pt-2" lg={1}>
            <Form.Group className="" controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="10/11/2021 12:00PM" />
            </Form.Group>
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            <Form.Group className="" controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="" />
            </Form.Group>
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            <Form.Group className=" " controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="" />
            </Form.Group>{' '}
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0 " lg={1}>
            <Form.Group className=" " controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="" />
            </Form.Group>
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            10/11/2021
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            <SmallButton variant="" className="btn-border">
              Book
            </SmallButton>
          </SmallFontCol>
          <SmallFontCol className="tableContent" lg={1}>
            -
          </SmallFontCol>
          <SmallFontCol className="tableContent " lg={1}>
            10/11/2021
          </SmallFontCol>
          <SmallFontCol className="tableContent ps-lg-5" lg={1}>
            <Pencilround />
          </SmallFontCol>
        </Row>

        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="tableContent" lg={1}>
            20008{' '}
          </Col>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            Chandrika Paytons{' '}
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0 pt-2" lg={1}>
            <Form.Group className=" " controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="10/11/2021 12:00PM" />
            </Form.Group>
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            <Form.Group className=" " controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="" />
            </Form.Group>
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            <Form.Group className=" " controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="" />
            </Form.Group>{' '}
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0 " lg={1}>
            <Form.Group className=" " controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="" />
            </Form.Group>
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            10/11/2021
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            <SmallButton variant="" className="btn-purple">
              Edit
            </SmallButton>
          </SmallFontCol>
          <SmallFontCol className="tableContent" lg={1}>
            -
          </SmallFontCol>
          <SmallFontCol className="tableContent " lg={1}>
            10/11/2021
          </SmallFontCol>
          <SmallFontCol className="tableContent ps-lg-5" lg={1}>
            <Pencilround />
          </SmallFontCol>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="tableContent" lg={1}>
            20008{' '}
          </Col>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            Chandrika Paytons{' '}
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0 pt-2" lg={1}>
            <Form.Group className="" controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="10/11/2021 12:00PM" />
            </Form.Group>
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            <Form.Group className="" controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="" />
            </Form.Group>
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            <Form.Group className=" " controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="" />
            </Form.Group>{' '}
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0 " lg={1}>
            <Form.Group className=" " controlId="formBasicCheckboxWord">
              <SmallCheck type="checkbox" label="" />
            </Form.Group>
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            10/11/2021
          </SmallFontCol>
          <SmallFontCol className="tableContent pe-0" lg={1}>
            <SmallButton variant="" className="btn-border">
              Book
            </SmallButton>
          </SmallFontCol>
          <SmallFontCol className="tableContent" lg={1}>
            -
          </SmallFontCol>
          <SmallFontCol className="tableContent " lg={1}>
            10/11/2021
          </SmallFontCol>
          <SmallFontCol className="tableContent ps-lg-5" lg={1}>
            <Pencilround />
          </SmallFontCol>
        </Row>
      </Container>
    </>
  );
}
const SmallFontCol = styled(Col)`
  font: normal normal 600 12px Poppins !importnant;
`;
const SmallButton = styled(Button)`
  font: normal normal 600 11px Poppins !important;
  height: 30px !important;
  min-width:60px !important;
  padding-left:15px !important;
  padding-right:15px !important;
`;
const SmallCheck = styled(Form.Check)`
  label {
    font: normal normal 600 11px Poppins !important;
    color: #a4a2dc;
  }
  input[type='checkbox'] {
    width: 15px !important;
    height: 15px !important;
  }
  input:checked[type='checkbox'] {
    background-size: 9px;
  }

  
`;
