import React from 'react'
import { Col, Row } from 'react-bootstrap'
import chevronSVG from "../../assets/images/chevron.svg";


export default function OrdersToMade() {
    return (
        <div className='grey-border py-2 my-4'>
            <p className='dashboard-title  ps-2'>Orders that need to be made</p>
            <Row className=' px-lg-2 mx-0 pb-3 align-items-center'>
                <Col className='tableTitle ps-0' lg={4}>BRIDE NAME<img className='ps-2' src={chevronSVG} alt='chevron' /> </Col>
                <Col className='tableTitle pe-0 ps-0' lg={2}>TIME <img className='ps-2' src={chevronSVG} alt='chevron' /></Col>
                <Col className='tableTitle ' lg={3}>DESIGNER <img className='ps-2' src={chevronSVG} alt='chevron' /></Col>
                <Col className='tableTitle pe-0 ps-0  ' lg={3}>CATALOGUE # <img className='ps-2' src={chevronSVG} alt='chevron' /></Col>

            </Row>
            <Row className=' px-lg-2 py-2 mx-0 mb-2 tableContentBg align-items-center'>
                <Col className='tableContent text-uppercase ps-0 pe-0 ' lg={4}>Jennifer Blackly</Col>
                <Col className='content-smallsize pe-0 ps-0 ' lg={2}>02/07/2021 At 1:00PM</Col>
                <Col className='content-smallsize ' lg={3}>Name written Here</Col>
                <Col className='content-smallsize pe-0 ps-0' lg={3}>12312</Col>
            </Row>
            <Row className=' px-lg-2 py-2 mx-0 mb-2 tableContentBg align-items-center'>
                <Col className='tableContent text-uppercase ps-0 pe-0 ' lg={4}>Jennifer Blackly</Col>
                <Col className='content-smallsize pe-0 ps-0 ' lg={2}>02/07/2021 At 1:00PM</Col>
                <Col className='content-smallsize ' lg={3}>Name written Here</Col>
                <Col className='content-smallsize pe-0 ps-0' lg={3}>12312</Col>
            </Row>
            <Row className=' px-lg-2 py-2 mx-0 mb-2 tableContentBg align-items-center'>
                <Col className='tableContent text-uppercase ps-0 pe-0 ' lg={4}>Jennifer Blackly</Col>
                <Col className='content-smallsize pe-0 ps-0 ' lg={2}>02/07/2021 At 1:00PM</Col>
                <Col className='content-smallsize ' lg={3}>Name written Here</Col>
                <Col className='content-smallsize pe-0 ps-0' lg={3}>12312</Col>
            </Row>

        </div>
    )
}
