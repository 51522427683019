import React from 'react'
import { Col, Row } from 'react-bootstrap'
import chevronSVG from "../../assets/images/chevron.svg";

export default function UncalledAppointments() {
    return (
        <div className='grey-border py-2 mt-4'>
            <p className='dashboard-title ps-2'>Uncalled appointment requests that need to be called</p>
            <Row className=' px-lg-2 mx-0 pb-3 align-items-center '>
                <Col className='tableTitle ps-0' lg={4}>BRIDE NAME <img className='ps-2' src={chevronSVG} alt='chevron' /></Col>
                <Col className='tableTitle' lg={5}>Phone number<img className='ps-2' src={chevronSVG} alt='chevron' /> </Col>
            </Row>
            <Row className=' px-lg-2  py-3 align-items-center tableContentBg mb-2 mx-0'>
                <Col className='tableContent text-uppercase ps-0' lg={4}>Jennifer Blackly</Col>
                <Col className='content-midsize  ' lg={5}>+1 5 2646 56465</Col>
            </Row>
            <Row className=' px-lg-2  py-3 align-items-center tableContentBg mb-2 mx-0'>
                <Col className='tableContent text-uppercase ps-0' lg={4}>Jennifer Blackly</Col>
                <Col className='content-midsize  ' lg={5}>+1 5 2646 56465</Col>
            </Row>
            <Row className=' px-lg-2  py-3 align-items-center tableContentBg mb-2 mx-0'>
                <Col className='tableContent text-uppercase ps-0' lg={4}>Jennifer Blackly</Col>
                <Col className='content-midsize  ' lg={5}>+1 5 2646 56465</Col>
            </Row>


        </div>
    )
}
