import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Pencilround from '../../Common/Button/Pencilround';
import Deleteround from '../../Common/Button/Deleteround';
export default function AddRefund() {
  return (
    <Container className="white-container-adjust addRefund pt-4 mb-4 pb-3 ">
      <p className="descTitle">Add Refund</p>
      <Row className="mb-3 ">
        <Col lg={6} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Label className="subTitle">DATE OF REFUND</Form.Label>
              <Form.Control type="date" placeholder="Date" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={6} sm={12}>
          <Form.Group className="mb-3" controlId="formBasicAmount">
            <Form.Label className="subTitle">REFUND AMOUNT (USD)</Form.Label>
            <Form.Control type="text" placeholder="Enter Refund Amount" />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end">
          <Button variant="" className="btn-purple">
            {' '}
            ADD REFUND
          </Button>
        </Col>
      </Row>

      <Row className="px-lg-0 pb-2 pt-5">
        <Col className="tableTitle" lg={3}>
          DATE OF REFUND{' '}
        </Col>
        <Col className="tableTitle" lg={7}>
          REFUND AMOUNT
        </Col>
        <Col className="tableTitle" lg={2}>
          ACTIONS
        </Col>
      </Row>
      <Row className="px-lg-2 pt-3 pb-3 tableContentBg mb-3">
        <Col className="tableContent" lg={3}>
          {' '}
          10/11/2021{' '}
        </Col>
        <Col className="tableContent" lg={7}>
          2000 USD
        </Col>
        <Col className="tableContent" lg={2}>
          {' '}
          <div className="text-t d-flex">
            <Pencilround />
            <div className="Deletebutton-table">
              <Deleteround />{' '}
            </div>
          </div>
        </Col>{' '}
      </Row>
    </Container>
  );
}
