import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Pencilround from '../../Common/Button/Pencilround';
import Deleteround from '../../Common/Button/Deleteround';
export default function AddPayment() {
  return (
    <Container className="white-container-adjust addRefund pt-4 mb-4 pb-4 ">
      <p className="descTitle">Add Payment</p>
      <Row className="mb-3">
        <Col lg={12} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Label className="subTitle">POINT OF SALE</Form.Label>
              <Form.Check
                name="radio"
                className="pe-5"
                type="radio"
                id=""
                label="Use your own point-of-sale system and enter data manually"
              />

              <Form.Check
                name="radio"
                type="radio"
                label="Use authorize net point of sale system"
                id=""
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg={6} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Label className="subTitle">DATE OF PAYMENT</Form.Label>
              <Form.Control type="date" placeholder="Choose Date of Payment" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={6} sm={12}>
          <Form.Group className="mb-3" controlId="formBasicDate">
            <Form.Label className="subTitle">PAYMENT AMOUNT (USD)</Form.Label>
            <Form.Control type="text" placeholder="Enter Payment Amount" />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end">
          <Button variant="" className="btn-purple">
            {' '}
            ADD PAYMENT
          </Button>
        </Col>
      </Row>

      <Row className="px-lg-0 pb-2 pt-5">
        <Col className="tableTitle" lg={3}>
          DATE OF REFUND{' '}
        </Col>
        <Col className="tableTitle" lg={7}>
          PAYMENT AMOUNT
        </Col>
        <Col className="tableTitle" lg={2}>
          ACTIONS
        </Col>
      </Row>
      <Row className="px-lg-2 pt-3 pb-3 tableContentBg mb-3">
        <Col className="tableContent" lg={3}>
          {' '}
          10/11/2021{' '}
        </Col>
        <Col className="tableContent" lg={7}>
          2000 USD
        </Col>
        <Col className="tableContent" lg={2}>
          {' '}
          <div className="text-t d-flex">
            <Pencilround />
            <div className="Deletebutton-table">
              <Deleteround />{' '}
            </div>
          </div>
        </Col>{' '}
      </Row>
    </Container>
  );
}
