import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import chevronSVG from '../../assets/images/chevron.svg';
import styled from 'styled-components';
import CustomSelect from '../Common/Select/CustomSelect';

export default function StatusOfOrders() {
  return (
    <>
      <Container className="general-title">
        <h1>Status of Orders</h1>
        <label>Add, Manage and Edit Orders</label>
      </Container>
      <Container className="white-container pt-5 pb-5">
        <div className="   position-relative ">
          <Row className="align-items-center pb-3 justify-content-between px-4">
            <Col lg={4} md={12}>
              <input
                className="searchInput"
                type="search"
                placeholder="Search"
              />
            </Col>
            <Col lg={4} md={12}>
              <Form className="">
                <Form.Group
                  className="d-flex justify-content-end"
                  controlId="formBasicDate"
                >
                  <Form.Label className="subTitle align-self-center text-end pe-2 pt-2">
                    Records Per Page
                  </Form.Label>
                  <CustomSelect />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
        <Row className=" px-lg-2 py-2 align-items-center ">
          <SmallFontCol className="tableTitle pe-0 ps-0" lg={1}>
            ORDER STATUS <img className="ps-0" src={chevronSVG} alt="chevron" />{' '}
          </SmallFontCol>
          <SmallFontCol className="tableTitle" lg={2}>
            DATE OF SALE TO BRIDE{' '}
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle pe-0 ps-0" lg={1}>
            BRIDE'S NAME <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle ps-5 " lg={2}>
            WEDDING DATE <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle " lg={2}>
            ESTIMATED SHIP DATE{' '}
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle pe-0 ps-0" lg={2}>
            SHIPPING DATE FROM SUPPLIER
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
          <SmallFontCol className="tableTitle ps-4" lg={2}>
            PICKUP DATE
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </SmallFontCol>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg align-items-center mb-2">
          <Col className="tableContent circleConteny text-center" lg={1}>
            <div className="circle-rose"></div>{' '}
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent itemName pe-0 ps-0" lg={1}>
            BREANNA MILLER
          </Col>
          <Col className="tableContent  ps-5" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent " lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent pe-0 ps-0" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-4" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg align-items-center mb-2">
          <Col className="tableContent circleConteny text-center" lg={1}>
            <div className="circle-rose"></div>{' '}
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent itemName pe-0 ps-0" lg={1}>
            BREANNA MILLER
          </Col>
          <Col className="tableContent ps-5" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent pe-0 ps-0" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-4" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg align-items-center mb-2">
          <Col className="tableContent circleConteny text-center" lg={1}>
            <div className="circle-green"></div>{' '}
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent itemName pe-0 ps-0" lg={1}>
            BREANNA MILLER
          </Col>
          <Col className="tableContent ps-5" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent pe-0 ps-0" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-4" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg align-items-center mb-2">
          <Col className="tableContent circleConteny text-center" lg={1}>
            <div className="circle-green"></div>
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent itemName pe-0 ps-0" lg={1}>
            BREANNA MILLER
          </Col>
          <Col className="tableContent ps-5" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent pe-0 ps-0" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-4" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg align-items-center mb-2">
          <Col className="tableContent circleConteny text-center" lg={1}>
            <div className="circle-beige"></div>
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent itemName pe-0 ps-0" lg={1}>
            BREANNA MILLER
          </Col>
          <Col className="tableContent ps-5" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent pe-0 ps-0" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-4" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg align-items-center mb-2">
          <Col className="tableContent circleConteny text-center" lg={1}>
            <div className="circle-yellow"></div>
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent itemName pe-0 ps-0" lg={1}>
            BREANNA MILLER
          </Col>
          <Col className="tableContent ps-5" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent pe-0 ps-0" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-4" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
        </Row>

        <div className="page-button">
          <Button className="left-button-pagination">
            <img
              className="left-image-button-pagination"
              src={chevronSVG}
              alt="chevron"
            />
          </Button>
          <Button className="button-pagination current-page">1</Button>
          <Button className="button-pagination">2</Button>
          <Button className="button-pagination">3</Button>
          <Button className="button-pagination">4</Button>
          <Button className="button-pagination">5</Button>
          <Button className="right-button-pagination">
            <img
              className="right-image-button-pagination"
              src={chevronSVG}
              alt="chevron"
            />
          </Button>
        </div>

        <div>
          <div className="d-flex align-items-center">
            <div className="circle-rose"></div>
            <p className="circle-description">Not yet ordered from supplier</p>
          </div>
          <div className="d-flex align-items-center">
            <div className="circle-beige"></div>
            <p className="circle-description">
              Ordered from supplier but not yet delivered
            </p>
          </div>
          <div className="d-flex align-items-center">
            <div className="circle-yellow"></div>
            <p className="circle-description">
              Ordered from supplier, delivered but not yet picked up by the
              bride
            </p>
          </div>
          <div className="d-flex align-items-center">
            <div className="circle-green"></div>
            <p className="circle-description">
              Ordered delivered and pickup by the bride
            </p>
          </div>
        </div>
      </Container>
    </>
  );
}
const SmallFontCol = styled(Col)`
  font-size: 10px;
`;
