import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import chevronSVG from '../../assets/images/chevron.svg';
import CopyRound from '../Common/Button/CopyRound';
import Deleteround from '../Common/Button/Deleteround';
import Pencilround from '../Common/Button/Pencilround';
import CustomSelect from '../Common/Select/CustomSelect';


export default function Inventory() {
  return (
    <>
      <Container className="general-title">
        <h1>Inventory</h1>
        <label>Add, Manage and Edit Inventories</label>
      </Container>
      <Container className="white-container py-5 mb-5">
        <div className="   position-relative">
          <Row className=' align-items-center pb-3 px-4 '>
            <Col lg={3} md={12}>
              <input
                className="searchInput"
                type="search"
                placeholder="Search"
              />
            </Col>
            <Col lg={4} md={12}>
              <Form className="">
                <Form.Group className="d-flex justify-content-center " controlId="formBasicRecords">
                  <Form.Label className="subTitle  align-self-center text-end ps-4 pe-2 pt-2">
                    Records Per Page
                  </Form.Label>
                  <CustomSelect />
                </Form.Group>
              </Form>
            </Col>
            <Col lg={5} md={12} className="d-flex justify-content-evenly ps-4">
              <Link to="add-designer">
                <Button variant="" className="btn-darkpurple">
                  ADD NEW DESIGNER
                </Button>
              </Link>
              <Link to="add-inventory">
                <Button variant="" className="btn-purple">
                  ADD INVENTORY ITEM
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
        <Row className=" px-lg-2 py-2 justify-content-evenly align-items-center">
          <Col className="tableTitle pe-0 " lg={1}>
            ITEM NAME
            <img className="ps-2" src={chevronSVG} alt="chevron" />{' '}
          </Col>
          <Col className="tableTitle ps-0 pe-0" lg={1}>
            ITEM IMAGE <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2}>
            MANUFACTURER'S CODE{' '}
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle ps-0 pe-0" lg={1}>
            RETAIL PRICE <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle " lg={2}>
            DESIGNER <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle ps-0 pe-0" lg={1}>
            DRESS SIZE <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2}>
            PURCHASE DATE{' '}
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle ps-5" lg={2}>
            ACTIONS
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="itemName   pe-0 " lg={1}>
            STRAPS
          </Col>
          <Col className="tableContent ps-0 pe-0" lg={1}>
            -
          </Col>
          <Col className="tableContent" lg={2}>
            Tiara
          </Col>
          <Col className="tableContent" lg={1}>
            954
          </Col>
          <Col className="tableContent" lg={2}>
            bling
          </Col>
          <Col className="tableContent" lg={1}>
            8
          </Col>
          <Col className="tableContent" lg={2}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-5 d-flex" lg={2}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div className="pe-2">
              <CopyRound />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="itemName  pe-0 " lg={1}>
            STRAPS
          </Col>
          <Col className="tableContent ps-0 pe-0" lg={1}>
            -
          </Col>
          <Col className="tableContent" lg={2}>
            Tiara
          </Col>
          <Col className="tableContent" lg={1}>
            954
          </Col>
          <Col className="tableContent" lg={2}>
            bling
          </Col>
          <Col className="tableContent" lg={1}>
            8
          </Col>
          <Col className="tableContent" lg={2}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-5 d-flex" lg={2}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div className="pe-2">
              <CopyRound />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="itemName  pe-0 " lg={1}>
            STRAPS
          </Col>
          <Col className="tableContent ps-0 pe-0" lg={1}>
            -
          </Col>
          <Col className="tableContent" lg={2}>
            Tiara
          </Col>
          <Col className="tableContent" lg={1}>
            954
          </Col>
          <Col className="tableContent" lg={2}>
            bling
          </Col>
          <Col className="tableContent" lg={1}>
            8
          </Col>
          <Col className="tableContent" lg={2}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-5 d-flex" lg={2}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div className="pe-2">
              <CopyRound />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="itemName pe-0 " lg={1}>
            STRAPS
          </Col>
          <Col className="tableContent ps-0 pe-0" lg={1}>
            -
          </Col>
          <Col className="tableContent" lg={2}>
            Tiara
          </Col>
          <Col className="tableContent" lg={1}>
            954
          </Col>
          <Col className="tableContent" lg={2}>
            bling designer
          </Col>
          <Col className="tableContent" lg={1}>
            8
          </Col>
          <Col className="tableContent" lg={2}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-5 d-flex" lg={2}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div className="pe-2">
              <CopyRound />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="itemName  pe-0 " lg={1}>
            STRAPS
          </Col>
          <Col className="tableContent ps-0 pe-0" lg={1}>
            -
          </Col>
          <Col className="tableContent" lg={2}>
            Tiara
          </Col>
          <Col className="tableContent" lg={1}>
            954
          </Col>
          <Col className="tableContent" lg={2}>
            bling
          </Col>
          <Col className="tableContent" lg={1}>
            8
          </Col>
          <Col className="tableContent" lg={2}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-5 d-flex" lg={2}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div className="pe-2">
              <CopyRound />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="itemName  pe-0 " lg={1}>
            STRAPS
          </Col>
          <Col className="tableContent ps-0 pe-0" lg={1}>
            -
          </Col>
          <Col className="tableContent" lg={2}>
            Tiara
          </Col>
          <Col className="tableContent" lg={1}>
            954
          </Col>
          <Col className="tableContent" lg={2}>
            bling designer
          </Col>
          <Col className="tableContent" lg={1}>
            8
          </Col>
          <Col className="tableContent" lg={2}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="tableContent ps-5 d-flex" lg={2}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div className="pe-2">
              <CopyRound />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
