import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';

import 'react-phone-input-2/lib/style.css';
export default function PostSale() {
  return (
    <Container className="purple-background-container-adjust pt-4 mb-4 pb-4 ">
      <p className="descTitle mb-0">Post-sale Order Details</p>
      <p className="header-2 mb-0">Has this item been ordered from the supplier?</p>
      <Row className="mb-1 align-items-center">
        <Col lg={4} sm={12}>
          <Form>
            <Form.Group className="mb-2" controlId="formBasicDate">
              <div className="mb-3 d-flex ">
                <Form.Check
                  name="ab"
                  className="pe-5"
                  type="radio"
                  id=""
                  label="Yes"
                />

                <Form.Check
                  name="ab"
                  className="pe-5 ms-auto"
                  type="radio"
                  id=""
                  label="No"
                />
              </div>
            </Form.Group>
          </Form>
        </Col>
        <Col lg={2} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <div className="mb-3 d-flex"></div>
            </Form.Group>
          </Form>
        </Col>
        <Col lg={6} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Control type="date" placeholder="Enter Date" />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <p className="header-2 mb-0">Estimated ship date</p>

      <Row className="mb-2 padding-top-24 align-items-center">
        <Col lg={6} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Control type="date" placeholder="Enter Date" />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <p className="header-2 mb-0">
        Has this item been delivered from the supplier?
      </p>
      <Row className="mb-2 padding-top-24 align-items-center">
        <Col lg={4} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <div className="mb-3 d-flex">
                <Form.Check
                  name="ab"
                  className="pe-5"
                  type="radio"
                  id=""
                  label="Yes"
                />

                <Form.Check
                  name="ab"
                  className="pe-5 ms-auto"
                  type="radio"
                  id=""
                  label="No"
                />
              </div>
            </Form.Group>
          </Form>
        </Col>
        <Col lg={2} sm={12}>
          <Form>
            <Form.Group className="mb-2" controlId="formBasicDate">
              <div className="mb-3 d-flex"></div>
            </Form.Group>
          </Form>
        </Col>
        <Col lg={6} sm={12}>
          <Form>
            <Form.Group className="mb-2" controlId="formBasicDate">
              <Form.Control type="date" placeholder="Enter Date" />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <p className="header-2 mb-0">Has this item been picked up by the bride?</p>
      <Row className="mb-2 padding-top-24 align-items-center">
        <Col lg={4} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <div className="mb-3 d-flex">
                <Form.Check
                  name="ab"
                  className="pe-5"
                  type="radio"
                  id=""
                  label="Yes"
                />

                <Form.Check
                  name="ab"
                  className="pe-5 ms-auto"
                  type="radio"
                  id=""
                  label="No"
                />
              </div>
            </Form.Group>
          </Form>
        </Col>
        <Col lg={2} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <div className="mb-3 d-flex"></div>
            </Form.Group>
          </Form>
        </Col>
        <Col lg={6} sm={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Control type="date" placeholder="Enter Date" />
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
