import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import chevronSVG from '../../assets/images/chevron.svg';
import CustomSelect from '../Common/Select/CustomSelect';

export default function AddingExistingCustomer() {
  return (
    <>
      <Container className="general-title">
        <h1>Add Existing Customer</h1>
        <label>Search for and Add an Existing Customer</label>
      </Container>
      <Container className="white-container pt-4">
        <div className="   position-relative">
          <Row>
            <Col lg={6} md={12}>
              <input
                className="searchInput"
                type="search"
                placeholder="Search"
              />
            </Col>
            <Col lg={6} md={12}>
              <Form className="">
                <Form.Group className="d-flex " controlId="formBasicRecords">
                  <Form.Label className="subTitle w-100 align-self-center text-end pe-2 pt-2">
                    Records Per Page
                  </Form.Label>
                  <CustomSelect />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
        <Row className=" px-lg-2 pt-3 pb-3">
          <Col className="tableTitle" lg={2}>
            BRIDE NAME <img className="ps-2" src={chevronSVG} alt="chevron" />{' '}
          </Col>
          <Col className="tableTitle" lg={2}>
            PHONE NUMBER <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2}>
            EMAIL <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2}>
            WEDDING DATE <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2}>
            ESTIMATED BUDGET{' '}
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2}>
            ACTIONS
          </Col>
        </Row>
        <Row className=" px-lg-2 pt-3 pb-3 tableContentBg mb-3">
          <Col className="tableContent" lg={2}>
            BOBBILYN BRUURSEMA{' '}
          </Col>
          <Col className="tableContent" lg={2}>
            (231) 580-1159
          </Col>
          <Col className="tableContent" lg={2}>
            zanetataylor@gmail.com
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021
          </Col>
          <Col className="tableContent" lg={2}>
            Between 500- 1000$
          </Col>
          <Col className="tableContent" lg={2}>
            <Button variant="" className="btn-darkpurple">
              Add existing customer
            </Button>
          </Col>
        </Row>
        <Row className=" px-lg-2 pt-3 pb-3 tableContentBg mb-3">
          <Col className="tableContent" lg={2}>
            BOBBILYN BRUURSEMA{' '}
          </Col>
          <Col className="tableContent" lg={2}>
            (231) 580-1159
          </Col>
          <Col className="tableContent" lg={2}>
            zanetataylor@gmail.com
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021
          </Col>
          <Col className="tableContent" lg={2}>
            Between 500- 1000$
          </Col>
          <Col className="tableContent" lg={2}>
            <Button variant="" className="btn-darkpurple">
              Add existing customer
            </Button>
          </Col>
        </Row>
        <Row className=" px-lg-2 pt-3 pb-3 tableContentBg mb-3">
          <Col className="tableContent" lg={2}>
            BOBBILYN BRUURSEMA{' '}
          </Col>
          <Col className="tableContent" lg={2}>
            (231) 580-1159
          </Col>
          <Col className="tableContent" lg={2}>
            zanetataylor@gmail.com
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021
          </Col>
          <Col className="tableContent" lg={2}>
            Between 500- 1000$
          </Col>
          <Col className="tableContent" lg={2}>
            <Button variant="" className="btn-darkpurple">
              Add existing customer
            </Button>
          </Col>
        </Row>
        <Row className=" px-lg-2 pt-3 pb-3 tableContentBg mb-3">
          <Col className="tableContent" lg={2}>
            BOBBILYN BRUURSEMA{' '}
          </Col>
          <Col className="tableContent" lg={2}>
            (231) 580-1159
          </Col>
          <Col className="tableContent" lg={2}>
            zanetataylor@gmail.com
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021
          </Col>
          <Col className="tableContent" lg={2}>
            Between 500- 1000$
          </Col>
          <Col className="tableContent" lg={2}>
            <Button variant="" className="btn-darkpurple">
              Add existing customer
            </Button>
          </Col>
        </Row>
        <Row className=" px-lg-2 pt-3 pb-3 tableContentBg mb-3">
          <Col className="tableContent" lg={2}>
            BOBBILYN BRUURSEMA{' '}
          </Col>
          <Col className="tableContent" lg={2}>
            (231) 580-1159
          </Col>
          <Col className="tableContent" lg={2}>
            zanetataylor@gmail.com
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021
          </Col>
          <Col className="tableContent" lg={2}>
            Between 500- 1000$
          </Col>
          <Col className="tableContent" lg={2}>
            <Button variant="" className="btn-darkpurple">
              Add existing customer
            </Button>
          </Col>
        </Row>
        <Row className=" px-lg-2 pt-3 pb-3 tableContentBg mb-3">
          <Col className="tableContent" lg={2}>
            BOBBILYN BRUURSEMA{' '}
          </Col>
          <Col className="tableContent" lg={2}>
            (231) 580-1159
          </Col>
          <Col className="tableContent" lg={2}>
            zanetataylor@gmail.com
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021
          </Col>
          <Col className="tableContent" lg={2}>
            Between 500- 1000$
          </Col>
          <Col className="tableContent" lg={2}>
            <Button variant="" className="btn-darkpurple">
              Add existing customer
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
