import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import icon from '../../assets/images/arrow.svg';
import CustomSelect from '../Common/Select/CustomSelect';
export default function AddInventoryItem() {
  return (
    <>
      <Container className="general-title">
        <h1>Add Inventory Item</h1>
        <label>Manage Inventory Item</label>
      </Container>
      <Container className="white-container p-4">
        <div className="mb-5">
          <img src={icon} alt="" />
          <Link className="backLink ps-1" to="/inventory">
            BACK TO INVENTORY
        </Link>
        </div>
        <p className="header-3">Item details</p>
        <Row className="mb-3">
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicCountry">
                <Form.Label className="subTitle">DESIGNER</Form.Label>
                <CustomSelect />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label className="subTitle">MANUFACTURER'S CODE</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Manufacturer's Code"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicLastName">
                <Form.Label className="subTitle">ITEM NAME</Form.Label>
                <Form.Control type="text" placeholder="Enter Item Name" />
              </Form.Group>
            </Form>
          </Col>
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicLastName">
                <Form.Label className="subTitle">DRESS SIZE</Form.Label>
                <Form.Control type="text" placeholder="Enter Dress Size" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicLastName">
                <Form.Label className="subTitle">RETAIL PRICE (USD)</Form.Label>
                <Form.Control type="text" placeholder="Enter Retail Price" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label className="subTitle">PURCHASE DATE</Form.Label>
                <Form.Control type="date" placeholder="Select Purchase Date" />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={6} sm={12}>
            <div>drop Item image</div>
          </Col>
          <Col lg={6} sm={12}>
            <Container className="Purple-background-container pt-2 pb-3">
              <Col lg={12} sm={12}>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicLastName">
                    <Form.Label className="subTitle">
                      WHOLESALE PRICE (USD)
                  </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Wholesale Price"
                    />
                  </Form.Group>
                  <div>drop Item image</div>
                </Form>
              </Col>
            </Container>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="" className="btn-purple">
              ADD ITEM
          </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
