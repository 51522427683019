import React from 'react';
import {Container, Form, Row,Col, Button } from 'react-bootstrap';

import 'react-phone-input-2/lib/style.css';
import StyledDropper from '../../Common/Dropper/StyledDropper';
export default function WholeSale() {
  return (
    <Container className="purple-background-container-adjust pt-4 mb-4 pb-4 ">
        <Form.Group className="mb-5" controlId="formBasicAmount">
            <Form.Label className="subTitle">WholeSale Price (USD)</Form.Label>
            <Form.Control type="text" placeholder="Enter Wholesale Price" />
        </Form.Group>
      <StyledDropper/>  
      <Row className='pt-3'>
        <Col className="d-flex justify-content-end">
          <Button variant="" className="btn-border me-3 btn">
            {' '}
            SAVE ORDER{' '}
          </Button>
          <Button variant="" className="btn-purple">
            {' '}
            REFUND
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
