import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AppointmentsDay from './AppointmentsDay';
import PickupsWeek from './PickupsWeek';
import UncalledAppointments from './UncalledAppointments';
import PickupsCalled from './PickupsCalled';
import OrdersToMade from './OrdersToMade';

export default function Dashboard() {
  return (
    <>
      <Container className="general-title">
        <h1>Dashboard</h1>
        <label>View and check your data</label>
      </Container>
      <Container className="white-container dashboard py-5">
        <div className="d-flex align-items-center ps-2 flex-wrap">
          <div className="circle-newApp"></div>
          <div className="ps-2 pe-3"> New Appointments </div>
          <div className="circle-pickups"></div>
          <div className="ps-2 pe-3">Pickups</div>
          <div className="circle-retryApp"></div>
          <div className="ps-2 pe-3">Retry Appointments</div>
          <div className="circle-unconf"></div>
          <div className="ps-2 pe-3">Unconfirmed Appointments</div>
        </div>
        <Row className='ps-2'>
          <Col lg={5}>
            <AppointmentsDay />
          </Col>

          <Col lg={5}>
            <PickupsWeek />
          </Col>
        </Row>
        <Row className='ps-2'>
          <Col lg={5}>
            <UncalledAppointments />
          </Col>

          <Col lg={5}>
            <PickupsCalled />
          </Col>
        </Row>
        <Row className='ps-2'>
          <Col lg={5}>
            <OrdersToMade />
          </Col>
          <Col lg={5}>

          </Col>
        </Row>
      </Container>
    </>
  );
}
