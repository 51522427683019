import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import icon from '../../assets/images/arrow.svg'
export default function EditInventoryItem() {
  return (
    <>
      <Container className="general-title">
        <h1>Add Reciept</h1>
        <label>Requests for appointments</label>
      </Container>
      <Container className='white-container p-5'>
        <div className='mb-5'>
          <img src={icon} alt='' />
          <a className='backLink ps-1' href='www.google.com'>BACK TO BOOK KEEPING</a>
        </div>
        <p className='header-3'>Receipt details</p>
        <Row className='mb-3'>
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicCountry">
                <Form.Label className='subTitle'>PRICE (USD)</Form.Label>
                <Form.Control type="text" placeholder="Enter Price" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label className='subTitle'>NOTE</Form.Label>
                <Form.Control type="text" placeholder="Enter notes..." />
              </Form.Group>

            </Form>

          </Col>
          <Col lg={6} sm={12}>

            <Form>
              <Form.Group className="mb-3" controlId="formBasicLastName">
                <Form.Label className='subTitle'>VENDOR</Form.Label>
                <Form.Control as="textarea" placeholder="Enter Vender" rows={3} />
              </Form.Group>


            </Form>
            <div>image drop</div>

          </Col>
        </Row>


        <Row>
          <Col className='d-flex justify-content-end'>

            <Button variant="" className='btn-purple' >ADD RECEIPT</Button>
          </Col>

        </Row>
      </Container>
    </>
  );
}

