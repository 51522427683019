import React, { useState } from 'react'
import Select from 'react-select';

export default function CustomSelect() {
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: '1-50', label: '1-50' },
        { value: '1-50', label: '1-50' },
        { value: '1-50', label: '1-50' },
    ];
    return (
        <div>
            <Select
                styles={{
                    dropdownIndicator: base => ({
                        ...base,
                        color: "#A4A2DC" // Custom colour
                    }),
                    control: (provided, state) => ({
                        ...provided,
                        boxShadow: "none",
                        borderRadius: "4px",
                        border: "1px solid #DDDDDD",
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        // border: "1px solid #E7E6E9",
                        boxShadow: "none",
                        marginTop: "-4px",

                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused && "#F9F5F4",

                        border: "1px solid #E7E6E9",
                        // color: "#AfAFAF",
                        color: state.isActive ? "#A4A2DC" : "#AfAFAF",
                        // state.isFocused && "#F9F5F4",
                    }),
                    menuList: (provided, state) => ({
                        ...provided,
                        paddingTop: 0,
                        marginTop: 0,
                        paddingBottom: 0,
                    }),
                    // singleValue: (provided, state) => ({
                    //   ...provided,
                    //   color: "#AfAFAF"
                    // }),
                }}

                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
                className='outerSelect'
                classNamePrefix='optionSelect'
                placeholder='1-50'

                components={{
                    IndicatorSeparator: () => null
                }}
            />
        </div>
    )
}
