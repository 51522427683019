import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
export default function OrderDetails() {
  return (
    <>
      <Container className="general-title-adjust">
        <h1>Orders</h1>
        <label>Add, Manage and Edit Orders</label>
      </Container>

      <Container className="white-container-adjust pt-4 mb-4 pb-4 ">
        <p className="descTitle">Order Details</p>
        <Row className="mb-3">
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label className="subTitle">BRIDE'S FIRSTNAME</Form.Label>
                <Form.Control type="text" placeholder="Enter Firstname" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label className="subTitle">DATE</Form.Label>
                <Form.Control type="date" placeholder="Date" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicAddress">
                <Form.Label className="subTitle">EMAIL ADDRESS</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter email address"
                />{' '}
              </Form.Group>
            </Form>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label className="subTitle">BRIDE'S LAST NAME</Form.Label>
              <Form.Control type="text" placeholder="Enter Last Name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicNum">
              <Form.Label className="subTitle">PHONE NUMBER</Form.Label>
              <PhoneInput country={'us'} />
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </>
  );
}
