import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import chevronSVG from '../../assets/images/chevron.svg';
import Deleteround from '../Common/Button/Deleteround';
import Pencilround from '../Common/Button/Pencilround';
import CustomSelect from '../Common/Select/CustomSelect';

export default function BookKeeping() {
  return (
    <>
      <Container className="general-title">
        <h1>Book Keeping</h1>
        <label>Add, Manage and Edit receipts</label>
      </Container>
      <Container className="white-container py-5">
        <div className="px-4 pb-3  position-relative">
          <Row>
            <Col lg={4} md={12}>
              <input
                className="searchInput"
                type="search"
                placeholder="Search"
              />
            </Col>
            <Col lg={4} md={12}>
              <Form className="">
                <Form.Group
                  className="d-flex justify-content-center"
                  controlId="formBasicRecords"
                >
                  <Form.Label className="subTitle align-self-center text-end pe-2 pt-2">
                    Records Per Page
                  </Form.Label>
                  <CustomSelect />
                </Form.Group>
              </Form>
            </Col>
            <Col lg={4} md={12} className="d-flex justify-content-end">
              <Link to="add-receipt">
                <Button variant="" className="btn-purple">
                  Add new receipt
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
        <Row className=" px-lg-2 pt-3 pb-3 justify-content-evenly align-items-center">
          <Col className="tableTitle" lg={1} sm={12}>
            PRICE
            <img className="ps-2" src={chevronSVG} alt="chevron" />{' '}
          </Col>
          <Col className="tableTitle" lg={2} sm={12}>
            VENDOR <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={3} sm={12}>
            NOTES <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={1} sm={12}>
            IMAGE <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2} sm={12}>
            CREATED BY <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2} sm={12}>
            CREATED AT <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={1} sm={12}>
            ACTIONS
          </Col>
        </Row>

        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="content-midsize" lg={1} sm={12}>
            20
          </Col>
          <Col className="tableContent" lg={2} sm={12}>
            Tiara
          </Col>
          <Col className="content-midsize" lg={3} sm={12}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          </Col>
          <Col className="content-midsize" lg={1} sm={12}>
            -
          </Col>
          <Col className="content-midsize" lg={2} sm={12}>
            Tiara
          </Col>
          <Col className="tableContent" lg={2} sm={12}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="tableContent d-flex" lg={1} sm={12}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="content-midsize" lg={1} sm={12}>
            20
          </Col>
          <Col className="tableContent" lg={2} sm={12}>
            Tiara
          </Col>
          <Col className="content-midsize" lg={3} sm={12}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          </Col>
          <Col className="content-midsize" lg={1} sm={12}>
            -
          </Col>
          <Col className="content-midsize" lg={2} sm={12}>
            Tiara
          </Col>
          <Col className="tableContent" lg={2} sm={12}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="tableContent d-flex" lg={1} sm={12}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="content-midsize" lg={1} sm={12}>
            20
          </Col>
          <Col className="tableContent" lg={2} sm={12}>
            Tiara
          </Col>
          <Col className="content-midsize" lg={3} sm={12}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          </Col>
          <Col className="content-midsize" lg={1} sm={12}>
            -
          </Col>
          <Col className="content-midsize" lg={2} sm={12}>
            Tiara
          </Col>
          <Col className="tableContent" lg={2} sm={12}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="tableContent d-flex" lg={1} sm={12}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
        <Row className=" px-lg-2 py-1 tableContentBg mb-2 justify-content-evenly align-items-center">
          <Col className="content-midsize" lg={1} sm={12}>
            20
          </Col>
          <Col className="tableContent" lg={2} sm={12}>
            Tiara
          </Col>
          <Col className="content-midsize" lg={3} sm={12}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          </Col>
          <Col className="content-midsize" lg={1} sm={12}>
            -
          </Col>
          <Col className="content-midsize" lg={2} sm={12}>
            Tiara
          </Col>
          <Col className="tableContent" lg={2} sm={12}>
            02/07/2021 AT 1:00PM
          </Col>
          <Col className="tableContent d-flex" lg={1} sm={12}>
            <div className="pe-2">
              <Pencilround />
            </div>
            <div>
              <Deleteround />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
