import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import chevronSVG from '../../assets/images/chevron.svg';
import CustomSelect from '../Common/Select/CustomSelect';

export default function RefundList() {
  return (
    <>
      <Container className="general-title">
        <h1>Refund Transactions</h1>
        <label>Manage and Edit Refund Transactions</label>
      </Container>
      <Container className="white-container py-5">
        <Row className="mb-3 ps-4 align-items-center">
          <Col lg={3} sm={12}>
            <Form>
              <Form.Group
                className="mb-3 d-flex lexique-component"
                controlId="formBasicDate"
              >
                <Form.Label className="subTitle pe-3">FROM</Form.Label>
                <Form.Control type="date" placeholder="Date" />
              </Form.Group>
            </Form>
          </Col>
          <Col lg={3} sm={12}>
            <Form>
              <Form.Group
                className="mb-3 d-flex lexique-component"
                controlId="formBasicDate"
              >
                <Form.Label className="subTitle pe-3">TO</Form.Label>
                <Form.Control type="date" placeholder="Date" />
              </Form.Group>
            </Form>
          </Col>
          <Col className="mb-0 mb-lg-3 mb-md-3" lg={3} md={12}>
            <Button variant="" className="btn-purple">
              SEARCH
            </Button>
          </Col>
        </Row>
        <hr />
        <p className="header-3 px-4 pb-5">List of Refund Transactions</p>
        <div className="position-relative ">
          <Row className="px-4 pb-3">
            <Col lg={4} md={12}>
              <input
                className="searchInput"
                type="search"
                placeholder="Search by name"
              />
            </Col>
            <Col lg={4} md={12}>
              <Form className="">
                <Form.Group
                  className="d-flex justify-content-center "
                  controlId="formBasicDate"
                >
                  <Form.Label className="subTitle  align-self-center  pe-2 pt-2">
                    Records Per Page
                  </Form.Label>
                  <CustomSelect />
                </Form.Group>
              </Form>
            </Col>
            <Col lg={4} md={12} className="text-center d-flex justify-content-end">
              <Button variant="" className="btn-purple">
                {' '}
                EXPORT DATA
              </Button>
            </Col>
          </Row>
        </div>
        <Row className=" px-lg-2 pt-3 pb-2 align-items-center ">
          <Col className="tableTitle ps-0 ps-lg-4 " lg={2}>
            BRIDE FIRST NAME
            <img className="ps-2" src={chevronSVG} alt="chevron" />{' '}
          </Col>
          <Col className="tableTitle" lg={2}>
            BRIDE LAST NAME
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2}>
            PHONE
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle ps-0 pe-0" lg={2}>
            EMAIL
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle ps-5" lg={2}>
            PAYMENT AMOUNT
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
          <Col className="tableTitle" lg={2}>
            DATE OF PAYMENT
            <img className="ps-2" src={chevronSVG} alt="chevron" />
          </Col>
        </Row>
        <Row className=" px-lg-2 py-2 mb-2 tableContentBg align-items-center">
          <Col className="tableContent ps-0 ps-lg-4" lg={2}>
            {' '}
            Whitney
          </Col>
          <Col className="tableContent" lg={2}>
            Pinder
          </Col>
          <Col className="tableContent " lg={2}>
            (616)283-2079
          </Col>
          <EmailFontCol
            className="tableContent ps-0 pe-0 overflow-hidden"
            lg={2}
          >
            whitney.sue.pinder@gmail.com
          </EmailFontCol>
          <Col className="tableContent ps-5" lg={2}>
            1537
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
        </Row>
        <Row className=" px-lg-2 py-2 mb-2 tableContentBg align-items-center">
          <Col className="tableContent ps-0 ps-lg-4" lg={2}>
            {' '}
            Whitney
          </Col>
          <Col className="tableContent" lg={2}>
            Pinder
          </Col>
          <Col className="tableContent " lg={2}>
            (616)283-2079
          </Col>
          <EmailFontCol
            className="tableContent ps-0 pe-0 overflow-hidden"
            lg={2}
          >
            whitney.sue.pinder@gmail.com
          </EmailFontCol>
          <Col className="tableContent ps-5" lg={2}>
            1537
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
        </Row>
        <Row className=" px-lg-2 py-2 mb-2 tableContentBg align-items-center">
          <Col className="tableContent ps-0 ps-lg-4" lg={2}>
            {' '}
            Whitney
          </Col>
          <Col className="tableContent" lg={2}>
            Pinder
          </Col>
          <Col className="tableContent " lg={2}>
            (616)283-2079
          </Col>
          <EmailFontCol
            className="tableContent ps-0 pe-0 overflow-hidden"
            lg={2}
          >
            whitney.sue.pinder@gmail.com
          </EmailFontCol>
          <Col className="tableContent ps-5" lg={2}>
            1537
          </Col>
          <Col className="tableContent" lg={2}>
            10/11/2021 AT 1:00PM
          </Col>
        </Row>


        <div className="page-button">
          <Button className="left-button-pagination">
            <img
              className="left-image-button-pagination"
              src={chevronSVG}
              alt="chevron"
            />
          </Button>
          <Button className="button-pagination current-page">1</Button>
          <Button className="button-pagination">2</Button>
          <Button className="button-pagination">3</Button>
          <Button className="button-pagination">4</Button>
          <Button className="button-pagination">5</Button>
          <Button className="right-button-pagination">
            <img
              className="right-image-button-pagination"
              src={chevronSVG}
              alt="chevron"
            />
          </Button>
        </div>
      </Container>
    </>
  );
}
const EmailFontCol = styled(Col)`
  font-size: 10px;
`;
