import './App.css';

import Sidebar from './components/Common/Sidebar';
import { Routes, Route } from "react-router-dom";


import BridalEntryDatabase from './components/BridalEntry/BridalEntryDatabase';
import BookAppointment from './components/Appointment/BookAppointment';
import StatusOfOrders from './components/StatusOrders/StatusOfOrders';
import Orders from './components/Orders/Orders';
import AddingExistingCustomer from './components/Orders/AddingExistingCustomer';
import AddingNewCustomer from './components/Orders/AddingNewCustomer.jsx';
import Dashboard from './components/Dashboard/Dashboard';
import SearchAppointment from './components/Appointment/SearchAppointment';
import Inventory from './components/Inventory/Inventory';

import PreviousInventory from './components/PreviousInventory/PreviousInventory';
import InventoryReport from './components/InventoryReport/InventoryReport';
import PaymentList from './components/PaymentTransactions/PaymentList';
import RefundList from './components/RefundTransactions/RefundList';
import Analytics from './components/Analytics/Analytics';
import BookKeeping from './components/BookKeeping/BookKeeping';
import ListTemplates from './components/Templates/ListTemplates'
import SMSTemplate from './components/Templates/SMSTemplate'
import ManageOrders from './components/Orders/OrderDetails/ManageOrders';
import AddNewDesigner from './components/Inventory/AddNewDesigner';
import AddInventoryItem from './components/Inventory/AddInventoryItem';
import AddReceipt from './components/BookKeeping/AddReceipt';

function App() {


  return (

    <div className="App">
      {/* <Home /> */}
      <Sidebar />

      <Routes>
        <Route path="/" element={<BookAppointment />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="bridal-entry" element={<BridalEntryDatabase />} />
        <Route path="calendar" element={<SearchAppointment />} />
        <Route path="orders" element={<Orders />} />
        <Route path="orders/add-exisiting-customer" element={<AddingExistingCustomer />} />
        <Route path="orders/add-new-customer" element={<AddingNewCustomer />} />
        <Route path="orders/order-details" element={<ManageOrders />} />
        <Route path="status-orders" element={<StatusOfOrders />} />
        <Route path="inventory" element={<Inventory />} />
        <Route path="inventory/add-designer" element={<AddNewDesigner />} />
        <Route path="inventory/add-inventory" element={<AddInventoryItem />} />
        <Route path="previous-inventory" element={<PreviousInventory />} />
        <Route path="inventory-report" element={<InventoryReport />} />
        <Route path="payment-list" element={<PaymentList />} />
        <Route path="refund-list" element={<RefundList />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="templates" element={<ListTemplates />} />
        <Route path="templates/sms-template" element={<SMSTemplate />} />
        <Route path="book-keeping" element={<BookKeeping />} />
        <Route path="book-keeping/add-receipt" element={<AddReceipt />} />
      </Routes>
    </div>

  );
}

export default App;
