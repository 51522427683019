import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import CustomSelect from '../Common/Select/CustomSelect';

export default function BookAppointment() {
  return (
    <>
      <Container className='general-title'>
        <h1>Book Appointment</h1>
        <label>Please fill the form below to book an appointment</label>
      </Container>
      <Container className='white-container pt-5 pb-5 ps-5 pe-5'>
        <Row className='mb-3'>
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label className='subTitle'>BRIDE'S FIRST NAME</Form.Label>
                <Form.Control type="text" placeholder="Please enter bride's first name" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label className='subTitle'>BRIDE'S WEDDING DATE</Form.Label>
                <Form.Control type="date" placeholder="Choose wedding date" />
              </Form.Group>
            </Form>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label className='subTitle'>BRIDE'S LAST NAME</Form.Label>
              <Form.Control type="text" placeholder="Please enter bride's first name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicESBUDGET">
              <Form.Label className='subTitle'>ESTIMATED BUDGET</Form.Label>
              <CustomSelect />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mb-3 '>
          <Col lg={6} sm={12}>
            <Form>
              <Form.Label className='subTitle'>Have you been wedding dress shopping before?</Form.Label>
              <div className="mb-3 d-flex">
                <Form.Check
                  name='radio'
                  className='pe-5'
                  type='radio'
                  label='Yes'
                />
                <Form.Check
                  name='radio'
                  type='radio'
                  label='No'
                />
              </div>
            </Form>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicNum">
                <Form.Label className='subTitle'>PHONE NUMBER</Form.Label>
                <PhoneInput
                  country={'us'}
                  specialLabel={''}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label className='subTitle'>EMAIL ADDRESS</Form.Label>
              <Form.Control type="text" placeholder="Please enter email address" />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mb-3 switch'>
          <p className='titleSection'>Where did you hear about us?</p>
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicAppDate">
                <Form.Label className='subTitle mb-0'>Preferred Appointment Date (Monday Day Off)</Form.Label>       <br />
                <Form.Label className='redTitle'>Please note appointment time is NOT CONFIRMED until we call you.</Form.Label>
                <Form.Control type="date" placeholder="Choose wedding date" />
              </Form.Group>
            </Form>
          </Col>
          <Col lg={6} sm={12}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicAppTime">
                <Form.Label className='subTitle mb-0'>Preferred Appointment Time (Monday Day Off)</Form.Label>  <br />
                <Form.Label className='redTitle'>Please note appointment time is NOT CONFIRMED until we call you.</Form.Label>
                <CustomSelect />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicCountry">
              <Form.Label className='subTitle'>Country</Form.Label>
              <CustomSelect />
            </Form.Group>
          </Col>
          <Col>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicCity">
                <Form.Label className='subTitle'>City</Form.Label>
                <Form.Control type="text" placeholder="Enter your city" />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label className='subTitle'>Address</Form.Label>
              <CustomSelect />
            </Form.Group>
          </Col>
          <Col>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicZip">
                <Form.Label className='subTitle'>Zip Code</Form.Label>
                <Form.Control type="text" placeholder="Enter ZIP Code" />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="formBasicHear">
              <Form.Label className='subTitle'>Where did you hear about us?</Form.Label>
              <CustomSelect />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row className='switch'>
          <Col>
            <Form.Group className="mb-3 d-flex " controlId="formBasicCheckboxPrivacy">
              <Form.Check type="checkbox" className='d-inline' />
              <label className='content ps-2'>I agree  to <span className='purpleText'> Privacy Policy</span> and <span className='purpleText'> Terms of Use</span></label>
            </Form.Group>
          </Col>
          <Col className='text-end'>
            <Button variant="" className='btn-purple '  > Submit </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
